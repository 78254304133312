<template >
  
    <div class="app-modal__body p-5 pb-0 mb-5">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <h3>{{ $t('message.price_list') }}</h3>
            <el-row :gutter="20" class="mb-2">
                <el-col :span="5">
                    <span class="font-bold">{{ $t('message.product') }}</span>
                </el-col>
                <el-col :span="5">
                    <span class="font-bold">{{ $t('message.directionTariff') }}</span>
                </el-col>
                <el-col :span="5">
                    <span class="font-bold">{{ $t('message.partner') }}</span>
                </el-col>
                <el-col :span="5">
                    <span class="font-bold">{{ $t('message.calculation_type') }}</span>
                </el-col>
                <el-col :span="4">
                    <span class="font-bold">{{ $t('message.price') }}</span>
                </el-col>
            </el-row>
            <el-form ref="form" :model="form">
                <el-row v-for="(price, index) in form.priceList" :key="'price_' + index" class="mb-1" :gutter="20">
                    <el-col :span="5">
                        {{ price.product ? price.product.name : '-' }}
                    </el-col>
                    <el-col :span="5">
                        {{ price.tariff ? price.tariff.name : '-' }}
                    </el-col>
                    <el-col :span="5">
                        {{ price.partner ? price.partner.name : '-' }}
                    </el-col>
                    <el-col :span="5">
                        <el-button type="warning" plain size="small">{{ getCalculationTypeName(price.calculation_type) }}</el-button>
                    </el-col>
                    <el-col :span="2">
                        <el-input
                            v-model="form.priceList[index].value"
                            type="number"
                            size="medium"
                        ></el-input>
                    </el-col>
                    <el-col :span="2" class="text-right">
                        <el-button @click="removePrice(index, price)" icon="el-icon-delete" type="danger" size="medium"></el-button>
                    </el-col>
                </el-row>
            </el-form>

            <div class="mt-4">
                <hr>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <select-product
                            ref="Product"
                            :size="'large'"
                            :multiple="true"
                            :placeholder="$t('message.product_id')"
                            :id="current_price.product_id"
                            v-model="current_price.product_id"
                        >
                        </select-product>
                    </el-col>
                    <el-col :span="8">
                        <select-tariff
                            ref="Tariff"
                            :size="'large'"
                            :show_all="true"
                            :multiple="true"
                            :placeholder="$t('message.directionTariff')"
                            :id="current_price.direction_tariff_id"  
                            v-model="current_price.direction_tariff_id"
                        >
                        </select-tariff>
                    </el-col>
                    <el-col :span="8">
                        <select-partner
                            :size="'large'"
                            class="w-100"
                            :multiple="true"
                            :placeholder="$t('message.partner')"
                            :id="current_price.partner_id"
                            v-model="current_price.partner_id"
                            :query="{clientType_id: 1, selected_id: current_price.partner_id}"
                        >
                        </select-partner>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="mt-2">
                    <el-col :span="8">
                        <select-calculation-types 
                            :size="'large'"
                            class="w-100"
                            v-model="current_price.calculation_type" 
                            :id="current_price.calculation_type"
                        ></select-calculation-types>
                    </el-col>
                    <el-col :span="8">
                        <crm-money-input v-model="current_price.value" :old="form.service_sum" :size="'large'"></crm-money-input>
                    </el-col>
                    <el-col :span="8" class="text-right">
                        <el-button @click="addNewPrice()" icon="el-icon-plus" class="w-100" type="primary">{{ $t('message.add') }}</el-button>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
       
</template>
  
<script>
    import { mapGetters, mapActions } from "vuex";
    import SelectTariff from '@/components/selects/select-direction-tariff';
    import selectPartner from "@/components/inventory/select-client";
    import selectProduct from "@/components/inventory/select-product";
    import SelectCalculationTypes from '@/components/inventory/select-service-calculation-types';


export default {
    components: {
        SelectTariff,
        selectPartner,
        selectProduct,
        SelectCalculationTypes
    },
    props: {
        calculation_type: {
            default: true
        },
        oldPriceList: {
            default: Array
        },
    },
    data() {
      return {
        form: {
            priceList: [],
        },
        new_price: {
            direction_tariff_id: null,
            partner_id: null,
            product_id: null,
            calculation_type: null,
            value: 0
        },
        current_price: {},
      };
    },
    created() {
        this.current_price = JSON.parse(JSON.stringify(this.new_price));
    },
    computed: {
        ...mapGetters({
            calculationTypes: "services/calculationTypes",
            productsInventory: "products/inventory",
            partnersInventory:  'clients/searchInventory',
            tariffsInventory: 'directionTariffs/inventory',
        }),
    },
    watch:{
        oldPriceList: {
            handler: async function(newVal, oldVal) {
                this.form.priceList = JSON.parse(JSON.stringify(newVal));
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        ...mapActions({
            // save: "services/store",
        }),
        getCalculationTypeName (c_type) {
            let calc = this.calculationTypes.find(el => el.id == c_type);
            return calc ? calc.name : '-';
        },
        addNewPrice() {
            if(this.current_price.calculation_type) {
                this.batchAddPrices();
                this.clearSelects(['Tariff', 'Product']);
                this.current_price = JSON.parse(JSON.stringify(this.new_price));
            } else {
                this.$notify({
                    title: this.$t('message.warning'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.please_select_input', {input: this.$t('message.calculation_type')})
                });
            }
        },
        isDublicatePrice(product_id, direction_tariff_id, partner_id) {
            return this.form.priceList.find(el => (
                el.direction_tariff_id == direction_tariff_id && el.partner_id == partner_id && el.product_id == product_id
            ));
        },
        batchAddPrices() {
            let products = this.current_price.product_id ? this.productsInventory.filter(el => this.current_price.product_id.includes(el.id)) : [];
            let tariffs = this.current_price.direction_tariff_id ? this.tariffsInventory.filter(el => this.current_price.direction_tariff_id.includes(el.id)) : [];
            let partners = this.current_price.partner_id ? this.partnersInventory.filter(el => this.current_price.partner_id.includes(el.id)) : [];
        
            if(products.length >= 0){
                products.push({id: null, name: null});
            }
            if(tariffs.length >= 0){
                tariffs.push({id: null, name: null});
            }
            if(partners.length >= 0){
                partners.push({id: null, name: null});
            }

            products.forEach(product => {
                tariffs.forEach(tariff => {
                    partners.forEach(partner => {
                        if((tariff.id || partner.id || product.id) && !this.isDublicatePrice(product.id, tariff.id, partner.id)){
                            this.form.priceList.push({
                                direction_tariff_id: tariff.id,
                                partner_id: partner.id,
                                product_id: product.id,
                                tariff: {
                                    id: tariff.id,
                                    name: tariff.name ? tariff.name : '-',
                                },
                                partner: {
                                    id: partner.id,
                                    name: partner.name ? partner.name : '-',
                                },
                                product: {
                                    id: product.id,
                                    name: product.name ? product.name : '-',
                                },
                                value: this.current_price.value,
                                calculation_type: this.current_price.calculation_type,
                            });
                        }
                    });
                });
            });
        },

        clearSelects(drawers) {
            drawers.forEach(drawer => {
                if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].emptyInput)) {
                    this.$refs[drawer].emptyInput()
                } 
            });
        },
        removePrice(index, row){
            this.form.priceList.splice(index, 1);
        },
    },
};
</script>
<style>
    .radio{
        width: 100%;
        margin-left: 12px;
    }
    .total_price{
        width: 100%;
        display: flex;
        flex-direction: row;
        margin: 30px 0 0 12px;
    }
    .price{
        width: 100%;
    }
    .percent{
        width: 50%;
    }
    .percent-slot{
        font-weight: bold;
        font-size: 17px;
        color: black;
    }
</style>