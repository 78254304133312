<template>
    <div>
      <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.calculation_type')" filterable clearable
        :size="size" class="w-100">
        <el-option v-for="(reason,index) in calculationTypes" :key="'calculationTypes-' + index" :label="reason.name" :value="reason.id"></el-option>
      </el-select>
    </div>
  </template>
  <script>
    import {mapGetters, mapActions} from "vuex";
    export default {
      props: {
        size: {
          default: 'small'
        },
        placeholder: {
          default: null,
        },
        id:{
          default: null
        }
      },
      watch:{
        id:{
          handler: function() {
              this.selected = this.id
          },
          immediate: true
        }
      },
      data() {
        return {
          selected: null,
        }
      },
      mounted() {
        if (this.calculationTypes && this.calculationTypes.length === 0) this.updateInventory()
      },
      computed: {
        ...mapGetters({
            calculationTypes: 'services/calculationTypes'
        })
      },
      methods: {
        ...mapActions({
          updateInventory: 'services/getCalculationTypes'
        }),
        dispatch(e) {
          this.$emit('input', e)
          this.selected = e;
          this.findAndEmit(e);
        },
        findAndEmit(id) {
          if(id){
            let item = this.calculationTypes.find(el => el.id == id);
            this.$emit('getSelectedItem', item);
          }
        },
      },
    }
  
  </script>
  